<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    width="70%"
    v-model="visible"
    @ok="toSubmit"
    okText="提交"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 21 }"
    >
      <a-row :gutter="4">
        <a-col :md="24">
          <a-form-model-item
            label="标签名称"
            prop="tagName"
          >
            <a-input v-model="rowData.tagName" placeholder="请输入标签名称"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="4">
        <a-col :md="24">
          <a-form-model-item label="标签描述" prop="tagDesc">
            <a-textarea
              v-model="rowData.tagDesc"
              placeholder="请输入标签描述"
              :auto-size="{ minRows: 3, maxRows: 21 }"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="4">
        <a-col :md="12">
          <a-form-model-item label="是否启用" prop="flagEnable" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-switch v-model="rowData.flagEnable" />
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="排序" prop="sort">
            <a-input-number v-model="rowData.sort" placeholder="排序" style="width: 100px"></a-input-number>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="4">
        <a-col :md="24">
          <a-form-model-item label="logo" prop="tagLogo">
            <QiniuUpload type="image" :value.sync="rowData.tagLogo"></QiniuUpload>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  editArticleTag,
  selectByIdArticleTagKonwLedge,
  addArticleTag,
} from '../api/ArticleApi'
export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        tagName: [{ required: true, message: '请输入标签名称', trigger: 'blur' }],
        flagEnable: [{ required: true, message: '请选择是否启用', trigger: 'blur' }],
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        logo: [{ required: true, message: '请上传logo', trigger: 'blur' }],
      },
      cateName: '',
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle, cateName) {
      this.handle = handle
      this.rowData = {}
      this.visible = true
      this.cateName = cateName
      if (handle === 'add') {
        this.$set(this.rowData, 'flagEnable', true)
      }
      if (handle === 'edit') {
        selectByIdArticleTagKonwLedge(row.id).then((res) => {
          this.rowData = res.body
        })
      }
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.rowData.categoryName = this.cateName
        const res = this.handle === 'add' ? await addArticleTag(this.rowData) : await editArticleTag(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>

</style>
